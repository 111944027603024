<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Outstanding</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Outstanding"
              v-model="filter.name"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click="getOutstanding(true)">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch-fourth p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button
          class="btn btn-success btn-md"
          @click="showInputModal(null, 'Form Add Outstanding')"
        >
          <i class="flaticon-add"></i> Add Outstanding
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="outstandingList">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Code</th>
              <th class="pl-7">Outstanding</th>
              <th class="pl-7">Type</th>
              <th class="pl-7">Category</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item: outstanding, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ outstanding.code }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ outstanding.name }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ outstanding.type ? outstanding.type : "-" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    outstanding.category_code
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8 text-center">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="
                    showInputModal(outstanding, 'Form Ubah Outstanding', false)
                  "
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="showDeleteModal(outstanding)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="input" hide-footer :title="modalTitle">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Code *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Code"
                v-model="form.code"
                :disabled="!isCreate"
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Outstanding *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Outstanding"
                v-model="form.name"
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Type *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Tipe"
                v-model="form.type"
              ></b-form-input>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Category *</label>

              <b-form-select
                v-model="form.category"
                :options="formattedCategoryOptions"
                size="lg"
              ></b-form-select>
            </div>

            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Status *</label>

              <b-form-select
                v-model="form.status"
                :options="formattedStatusOptions"
                size="lg"
              ></b-form-select>
            </div>
          </div>
        </form>

        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            block
            @click="isCreate ? saveOutstanding() : updateOutstanding()"
          >
            Save
          </b-button>

          <b-button
            :disabled="loading"
            class="btn btn-secondary mt-0"
            block
            @click="closeInputModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Are You Sure Want To Delete Outstanding</label>
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="deleteOutstanding"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  DELETE_OUTSTANDING,
  GET_OUTSTANDING,
  SAVE_OUTSTANDING,
  UPDATE_OUTSTANDING
} from "../../../core/services/store/outstanding.module";
import { GET_OUTSTANDING_CATEGORY } from "../../../core/services/store/outstanding-category.module";
import { GET_STATUS_OPTION } from "../../../core/services/store/status.module";

export default {
  components: { CompleteTable },

  data() {
    return {
      filter: {
        code: null,
        name: null
      },
      currentItem: null,
      modalTitle: null,
      form: {
        code: null,
        name: null,
        type: null,
        category: null,
        status: null
      },
      isCreate: true
    };
  },

  computed: {
    ...mapState({
      loading: state => state.outstanding.loading,
      outstandingList: state => state.outstanding.outstanding?.data,
      outstandingCategories: state =>
        state.outstandingCategory.outstandingCategories?.data,
      statuses: state => state.status.status?.data
    }),

    formattedStatusOptions() {
      let statuses = [];

      if (arrayMoreThanOne(this.statuses)) {
        statuses = this.statuses.map(project => {
          return {
            value: project.code,
            text: project.name
          };
        });
      }

      return [{ value: null, text: "Choose status" }, ...statuses];
    },

    formattedCategoryOptions() {
      let outstandingCategories = [];

      if (arrayMoreThanOne(this.outstandingCategories)) {
        outstandingCategories = this.outstandingCategories.map(project => {
          return {
            value: project.code,
            text: project.name
          };
        });
      }

      return [
        { value: null, text: "Choose Category" },
        ...outstandingCategories
      ];
    }
  },

  methods: {
    showInputModal(outstanding, modalTitle, isCreate = true) {
      this.currentItem = outstanding;

      this.form = {
        code: outstanding?.code,
        name: outstanding?.name,
        type: outstanding?.type,
        category: outstanding?.category_code,
        status: outstanding?.status_code
      };

      this.modalTitle = modalTitle;

      this.isCreate = isCreate;

      this.$refs["input"].show();
    },

    showDeleteModal(outstanding) {
      this.currentItem = outstanding;

      this.$refs["delete"].show();
    },

    closeInputModal() {
      this.$refs["input"].hide();
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    getOutstanding(filter = false) {
      if (!arrayMoreThanOne(this.outstandingList) || filter) {
        this.$store.dispatch(GET_OUTSTANDING, {
          params: this.filter
        });
      }
    },

    getOutstandingCategory() {
      if (!arrayMoreThanOne(this.outstandingList)) {
        this.$store.dispatch(GET_OUTSTANDING_CATEGORY, {
          params: {}
        });
      }
    },

    getStatus() {
      if (!arrayMoreThanOne(this.outstandingList)) {
        this.$store.dispatch(GET_STATUS_OPTION, {
          params: {}
        });
      }
    },

    deleteOutstanding() {
      this.$store
        .dispatch(DELETE_OUTSTANDING, {
          code: this.currentItem?.code
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    saveOutstanding() {
      this.$store.dispatch(SAVE_OUTSTANDING, this.prepareForm()).then(() => {
        this.closeInputModal();
      });
    },

    updateOutstanding() {
      this.$store.dispatch(UPDATE_OUTSTANDING, this.prepareForm()).then(() => {
        this.closeInputModal();
      });
    },

    prepareForm() {
      const status = this.formattedStatusOptions.find(
        statusOption => statusOption.value === this.form.status
      );

      const category = this.formattedCategoryOptions.find(
        categoryOption => categoryOption.value === this.form.category
      );

      return {
        ...this.form,
        status_code: status?.value,
        category_code: category?.value,
        status: {
          name: status?.text,
          code: status?.value
        },
        category: {
          name: category?.text,
          code: category?.value
        }
      };
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Outstanding" }]);

    this.getOutstanding();

    this.getOutstandingCategory();

    this.getStatus();
  }
};
</script>
